@import url("https://use.typekit.net/vsb4qqf.css");
@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;600&display=swap");

/* cage steel fonts */
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;600;700&display=swap");

/* ascot family day fonts */
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;500;700&display=swap");

/* leo fonts */

@import url("https://p.typekit.net/p.css?s=1&k=dsr3bpu&ht=tk&f=17016.33619&a=20068460&app=typekit&e=css");

/* Roland Garos */
@import url("https://fonts.googleapis.com/css2?family=Francois+One&family=Open+Sans&display=swap");

/* SGS */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;600;700&display=swap");

/* Fossil */
@import url("https://use.typekit.net/zik6xcm.css");

/* RugbyWC Capgemini */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap");

/* Infineon */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");

/* Others */

@font-face {
  font-family: "adelle-sans";
  src: url("https://use.typekit.net/af/a96fc4/00000000000000007735a1de/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/a96fc4/00000000000000007735a1de/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/a96fc4/00000000000000007735a1de/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "questa-grande";
  src: url("https://use.typekit.net/af/0b23d1/00000000000000007735af42/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/0b23d1/00000000000000007735af42/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/0b23d1/00000000000000007735af42/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

.tk-adelle-sans {
  font-family: "adelle-sans", sans-serif;
}
.tk-questa-grande {
  font-family: "questa-grande", serif;
}

*,
html {
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap");

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*::after,
*::before {
  padding: 0;
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  overflow-x: hidden;
}

body {
  position: relative;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: #000;
}

p {
  font-family: "neue-haas-grotesk-display";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 25px;
  margin: 0;
}

h1,
h2,
h3 {
  font-family: "acumin-pro-extra-condensed";
  margin: 0;
  color: #1d1d1f;
}

h4 {
  font-family: "neue-haas-grotesk-display";
  margin: 0;
  color: #1d1d1f;
}
